import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import HomePage from "./Pages/HomePage";
import MenuPage from "./Pages/MenuPage";

function App() {
  return (
   <BrowserRouter>
   <Routes>
   <Route index element={<HomePage />} />
   <Route path="/menu" element={<MenuPage />} />
   </Routes>
   
   </BrowserRouter>
  );
}

export default App;
