import React from 'react'
import Header from '../components/menu/Header/Header'
import Title from '../components/ulity/Title'
import Menusection from '../components/menu/MenuSection/MenuSection'
import Footer from '../components/ulity/Footer'

const MenuPage = () => {
  return (
     <div>
     <Header/>
     <Title title='BRANDS'/>
     <Menusection/>
     <Footer/>
     
     </div>

      
    
  )
}

export default MenuPage
