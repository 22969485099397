import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { allLabel, data, logos } from "../../../data.js";
import "animate.css";
import wow from "wowjs";
import SwiperCore, { Navigation } from "swiper/core";
import "./MenuSection.css";
import "swiper/css/navigation";
import Title from "../../ulity/Title.jsx";
import "../../ulity/Title.css";
import foto from "../../../Assets/images/49404-Juiciest-Hamburgers-Ever-mfs-052-4ee37878e27b4e409b54f2a8f7313f99.jpg";

function Menusection() {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedBrand, setSelectedBrand] = useState(1);
  const [Count, setCount] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  SwiperCore.use([Navigation]);
  const filterByCategory = (cat) => {
    setSelectedCategory(cat);
  };
  const filterByBrand = (cat) => {
    setSelectedBrand(cat.id);
    setSelectedCategory("all");
    setCount(cat.id);
  };

  const filteredLabels = allLabel.filter((label) => {
    return data.some(
      (item) => item.brand === selectedBrand && item.cat === label.name
    );
  });

  const color = [
    "#2c63ac",
    "#2c63ac",
    "#a3c626",
    "#009246",
    "#f0a20e",
    "#bd3928",
    "#b5987a",
    "#f08518",
    "#ec761d",
    "#d74b4b",
    "#e99515",
    "#88bd34",
    "#8c7426",
    "#eb2629",
  ];

  const colorBg = [
    "#2c63aa33",
    "#2c63aa33",
    "#a3c62633",
    "#00924633",
    "#f0a20e33",
    "#bd392833",
    "#b5987a33",
    "#f0851833",
    "#ec761d33",
    "#d74b4b33",
    "#e9951533",
    "#88bd3433",
    "#8c742633",
    "#eb26294d",
  ];
  useEffect(() => {
    const wowInstance = new wow.WOW();
    wowInstance.init();
    setWindowWidth(window.innerWidth);
  }, [selectedBrand]);

  const slidesPerView = windowWidth < 640 ? 3 : 4;
  return (
    <>
      <div className="menu-section">
        <div className="brand-card">
          <div className="swiper-Card">
            <Swiper slidesPerView={slidesPerView} navigation>
              {logos.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className={`${selectedBrand === item.id && "active-btn"}`}
                  onClick={() => filterByBrand(item)}
                >
                  <div className="brand-cardd">
                    <img
                      src={item.img}
                      width={100}
                      height={50}
                      style={{ objectFit: "cover" }}
                      alt={`Brand ${item.id}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div className="tabs-content">
        <Title title="MENU" />
        <div className={`tab ${selectedCategory && "active-tab"}`}>
          <div
            className="buttons meun-cat"
            style={{ backgroundColor: colorBg[Count] }}
          >
            <Swiper
              slidesPerView={slidesPerView}
              style={{ color: "#000" }}
              className="tab-buttons wow bounceInDown"
            >
              {filteredLabels.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className={`menu-menu-cat tab-btn ${
                    selectedCategory === item.name && "active-btn"
                  } `}
                  onClick={() => filterByCategory(item.name)}
                >
                  
                    <i className={`icon ${item.icon}`}></i>
                    <p className="item">{item.name}</p>
                    
                  
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="ss">
         {data.map((item,index)=>{
          return(
            <>
            {(selectedCategory === "lol" ||
            selectedCategory === "all" ||
            selectedCategory === item.cat) &&
          item.brand === selectedBrand ? (
          
          <div className="product-card">
          <div>
            {" "}
            <h1>{item.name}</h1>
            <p>{item.info}</p>
            <h2>{item.price}</h2>
          </div>
          <div className="center-img">
            <img className="product-img" src={item.img} alt="ssss" />
          </div>
          </div>
           
          ) : null}
            </>
          )
         })}
         </div>
        </div>
      </div>
    </>
  );
}

export default Menusection;
