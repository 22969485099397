import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div>
      <Link to='/menu'>
        <span> home</span>
      </Link>
    </div>
  );
};

export default HomePage;
