/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Footer.css"; // Import your CSS file for styling
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";
import logo from "../../Assets/logo/soonLogo_white.png";

const Footer = () => {
  return (
    <div>
    <footer className="footer">
    <div className="overlay"></div>
      <div className="FooterContainer">
        <div className="Footerlogo">
          <img src={logo} alt="sss" />
        </div>
        <div className="SosialMidiaIcons">
          <a href="https://www.facebook.com/">
            <i>
              <FaFacebook />
            </i>
          </a>
          <a href="https://www.instagram.com/">
            <i>
              <RiInstagramFill />
            </i>
          </a>
          <a href="https://twitter.com/">
            <i>
              <FaXTwitter />
            </i>
          </a>
          <a href="https://www.tiktok.com/">
            <i>
              <FaTiktok />
            </i>
          </a>
        </div>

       
      </div>
      <div className="FooterBottom">
          <p>
            Copyright &copy; Designed by{" "}
            <span className="company"> Smartinb</span>
          </p>
        </div>
     
    </footer>
    <div style={{height: 20}} ></div>
    </div>
  );
};

export default Footer;
