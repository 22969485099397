import img from "./Assets/logoImage/1.png";
import img2 from "./Assets/logoImage/2.png";
import img3 from "./Assets/logoImage/3.png";
import img4 from "./Assets/logoImage/4.png";
import img5 from "./Assets/logoImage/5.png";
import img6 from "./Assets/logoImage/6.png";
import img7 from "./Assets/logoImage/7.png";
import img8 from "./Assets/logoImage/8.png";
import img9 from "./Assets/logoImage/9.png";
import img10 from "./Assets/logoImage/10.png";
import img11 from "./Assets/logoImage/11.png";
import img12 from "./Assets/logoImage/12.png";
import img13 from "./Assets/logoImage/logo (2).png"
import def from "./Assets/images/49404-Juiciest-Hamburgers-Ever-mfs-052-4ee37878e27b4e409b54f2a8f7313f99.jpg";

export const data = [
  {
    id: 1,
    name: "Tereyağlı Yumurta",
    price: "50 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Kahvaltı",
    brand: 1,
  },
  {
    id: 2,
    name: "Sucuklu Yumurta",
    price: "80 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Kahvaltı",
    brand: 1,
  },
  {
    id: 3,
    name: "Menemen",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Kahvaltı",
    brand: 1,
  },
  {
    id: 4,
    name: "Kalem Böreği ",
    price: "50 ₺",
    info: "(6 Leziz Yemekler Güzel)",
    img: def,
    cat: "Kahvaltı",
    brand: 1,
  },
  {
    id: 5,
    name: "Çi Börek",
    price: "20 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Kahvaltı",
    brand: 1,
  },
  {
    id: 6,
    name: "Paçanga Böreği",
    price: "10 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Kahvaltı",
    brand: 1,
  },
  {
    id: 7,
    name: "Patates Kızartmas",
    price: "25 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Kahvaltı",
    brand: 1,
  },
  {
    id: 8,
    name: "Kızartma Tabağı ",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Kahvaltı",
    brand: 1,
  },
  {
    id: 9,
    name: "Humus ",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Soğuk Mezeler",
    brand: 2,
  },
  {
    id: 10,
    name: "Babagannuş ",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Soğuk Mezeler",
    brand: 2,
  },
  {
    id: 11,
    name: "Rus Salatası",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Soğuk Mezeler",
    brand: 2,
  },
  {
    id: 12,
    name: "Şakşuka",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Soğuk Mezeler",
    brand: 2,
  },
  {
    id: 13,
    name: "Zeytin Salatası",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Soğuk Mezeler",
    brand: 2,
  },
  {
    id: 14,
    name: "Acılı Ezme",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Soğuk Mezeler",
    brand: 2,
  },
  {
    id: 15,
    name: "Atom ",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Soğuk Mezeler",
    brand: 2,
  },
  {
    id: 16,
    name: "Muhammara ",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Soğuk Mezeler",
    brand: 2,
  },
  {
    id: 17,
    name: "Bahar Cacığı",
    price: "60 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Soğuk Mezeler",
    brand: 2,
  },
  {
    id: 18,
    name: "Falafel",
    price: "80 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Sıcak Mezeler",
    brand: 2,
  },
  {
    id: 19,
    name: "Fırın Mücver",
    price: "80 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Sıcak Mezeler",
    brand: 2,
  },
  {
    id: 20,
    name: "Söğürtme",
    price: "80 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Sıcak Mezeler",
    brand: 2,
  },
  {
    id: 21,
    name: "Kaşarlı Mantar",
    price: "80 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Sıcak Mezeler",
    brand: 2,
  },
  {
    id: 22,
    name: "Pa ₺ıcan Salatası",
    price: "80 ₺",
    info: "Leziz Yemekler Güzel ",
    img: def,
    cat: "Sıcak Mezeler",
    brand: 2,
  },
  {
    id: 23,
    name: "Pizza Margherita",
    price: "140 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Pizza",
    brand: 3,
  },
  {
    id: 24,
    name: "Pizza P1",
    price: "150 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Pizza",
    brand: 3,
  },
  {
    id: 25,
    name: "Pizza P2",
    price: "160 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Pizza",
    brand: 3,
  },
  {
    id: 26,
    name: "Pizza P3",
    price: "160 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Pizza",
    brand: 3,
  },
  {
    id: 27,
    name: "Lazanya L1 ",
    price: "150 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Lazanya",
    brand: 3,
  },
  {
    id: 28,
    name: "Lazanya L2",
    price: "150 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Lazanya",
    brand: 3,
  },
  {
    id: 29,
    name: "Lazanya V1",
    price: "140 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Lazanya",
    brand: 3,
  },
  {
    id: 30,
    name: "Mercimek Çorbası",
    price: "45 ₺",
    info: "info,info,info",
    img: def,
    cat: "Çorba",
    brand: 5,
  },
  {
    id: 31,
    name: "Ezogelin Çorbası ",
    price: "45 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Çorba",
    brand: 5,
  },
  {
    id: 32,
    name: "Kremalı Mantar",
    price: "65 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Çorba",
    brand: 5,
  },
  {
    id: 33,
    name: "Yayla Çorbası",
    price: "55 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Çorba",
    brand: 5,
  },
  {
    id: 34,
    name: "Domates Çorbası",
    price: "55 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Çorba",
    brand: 5,
  },
  {
    id: 35,
    name: "Sebze Çorbas",
    price: "55 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Çorba",
    brand: 5,
  },
  {
    id: 36,
    name: "Tavuk Çorbas",
    price: "65 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Çorba",
    brand: 5,
  },
  {
    id: 37,
    name: "Saray Çorbas",
    price: "85 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Çorba",
    brand: 5,
  },
  {
    id: 38,
    name: "Sezar Salata",
    price: "110 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Salata",
    brand: 5,
  },
  {
    id: 39,
    name: "Ton Balıklı Salata",
    price: "110 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Salata",
    brand: 5,
  },
  {
    id: 40,
    name: "Super Green",
    price: "85 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Salata",
    brand: 5,
  },
  {
    id: 41,
    name: "Tulumlu",
    price: "95 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Salata",
    brand: 5,
  },
  {
    id: 42,
    name: "Brassica",
    price: "110 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Salata",
    brand: 5,
  },
  {
    id: 43,
    name: "Çoban Salata",
    price: "85 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Salata",
    brand: 5,
  },
  {
    id: 44,
    name: "Gevur Dağı Saltası",
    price: "80 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Salata",
    brand: 5,
  },
  {
    id: 45,
    name: "Haşlama İçli Köfte ",
    price: "120 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Köfte",
    brand: 6,
  },
  {
    id: 46,
    name: "Antep İçli Köfte",
    price: "35 ₺",
    info: "Kızartma içli köfte",
    img: def,
    cat: "Köfte",
    brand: 6,
  },
  {
    id: 47,
    name: "Dolma & Sarma",
    price: "70 ₺",
    info: "Porsiyon olarak servis edilir",
    img: def,
    cat: "Köfte",
    brand: 6,
  },
  {
    id: 48,
    name: "Yalancı İçli Köfte",
    price: "100 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Köfte",
    brand: 6,
  },
  {
    id: 49,
    name: "Mantı",
    price: "100 ₺",
    info: "Yoğur ₺u, üzeri tereyağı soslu",
    img: def,
    cat: "Köfte",
    brand: 6,
  },
  {
    id: 50,
    name: "Soslu Köfte Topları",
    price: "120 ₺",
    info: "Soslu misket köf ₺e",
    img: def,
    cat: "Köfte",
    brand: 6,
  },
  {
    id: 51,
    name: "Tavuk Döner",
    price: "45 ₺",
    info: "Tavuk Döner",
    img: def,
    cat: "Doner",
    brand: 7,
  },
  {
    id: 52,
    name: "Nohut Dürüm",
    price: "40 ₺",
    info: "Nohut Dürüm",
    img: def,
    cat: "Doner",
    brand: 7,
  },
  {
    id: 53,
    name: "Ciğer Kavurma",
    price: "40 ₺",
    info: "Ciğer Kavurma",
    img: def,
    cat: "Doner",
    brand: 7,
  },
  {
    id: 54,
    name: "Ökçe Dürüm",
    price: "40 ₺",
    info: "Ökçe Dürüm",
    img: def,
    cat: "Doner",
    brand: 7,
  },
  {
    id: 55,
    name: "Kızartma Dürüm",
    price: "40 ₺",
    info: "Kızartma Dürüm",
    img: def,
    cat: "Doner",
    brand: 7,
  },
  {
    id: 56,
    name: "Classic Burger",
    price: "120 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Burger",
    brand: 8,
  },
  {
    id: 57,
    name: "Trüf Burger",
    price: "130 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Burger",
    brand: 8,
  },
  {
    id: 58,
    name: "Walter Burger",
    price: "140 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Burger",
    brand: 8,
  },
  {
    id: 59,
    name: "Vejeteryan Burger",
    price: "110 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Burger",
    brand: 8,
  },
  {
    id: 60,
    name: "Classic Fred",
    price: "120 ₺",
    info: "Leziz Yemekler Güzel",
    img: def,
    cat: "Kizarmis",
    brand: 9,
  },
  {
    id: 61,
    name: "Cheddar Fred",
    price: "120 ₺",
    info: "Kızarmış tavuk, cheddar peyniri, burger sos, soslu iceberg, kırmızı soğan",
    img: def,
    cat: "Kizarmis",
    brand: 9,
  },
  {
    id: 62,
    name: "Çıtır Kanat ",
    price: "80 ₺",
    info: "Soslu Çıtır Kana ₺ar 6 Leziz Yemekler Güzel",
    img: def,
    cat: "Kizarmis",
    brand: 9,
  },
  {
    id: 63,
    name: "Fred Box",
    price: "120 ₺",
    info: "Tavuk Göğüs, 4 ‘lü kanat, 4'lü tavuk topları",
    img: def,
    cat: "Kizarmis",
    brand: 9,
  },
  {
    id: 64,
    name: "Trüf Mondy",
    price: "130 ₺",
    info: "Kızarmış tavuk, trüf mantarı, burger sos, soslu iceberg, kırmızı soğan ",
    img: def,
    cat: "Kizarmis",
    brand: 9,
  },
  {
    id: 65,
    name: "Hot Fred ",
    price: "120 ₺",
    info: "Leziz Yemekler Güzel,acı sos",
    img: def,
    cat: "Kizarmis",
    brand: 9,
  },
  {
    id: 66,
    name: "Chicken Ball ",
    price: "50 ₺",
    info: "6'lı kızarmış tavuk topları",
    img: def,
    cat: "Kizarmis",
    brand: 9,
  },
  {
    id: 67,
    name: "Nugget",
    price: "60 ₺",
    info: "6'lı",
    img: def,
    cat: "Kizarmis",
    brand: 9,
  },
  {
    id: 68,
    name: "Fettuccine",
    price: "120 ₺",
    info: "Pesto Soslu, Napoliten Soslu, Alfredo Soslu",
    img: def,
    cat: "Makarna",
    brand: 10,
  },
  {
    id: 69,
    name: "Penne",
    price: "110 ₺",
    info: "Pesto Soslu, Napoliten Soslu, Alfredo Soslu",
    img: def,
    cat: "Makarna",
    brand: 10,
  },
  {
    id: 70,
    name: "Ravioli",
    price: "120 ₺",
    info: "Pesto Soslu, Napoliten Soslu, Alfredo Soslu",
    img: def,
    cat: "Makarna",
    brand: 10,
  },
  {
    id: 71,
    name: "Noodle",
    price: "110 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Makarna",
    brand: 10,
  },
  {
    id: 72,
    name: "Sade Kıyma",
    price: "200 ₺",
    info: "",
    img: def,
    cat: "Kebap",
    brand: 12,
  },
  {
    id: 73,
    name: "Adana",
    price: "200 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Kebap",
    brand: 12,
  },
  {
    id: 74,
    name: "Kuşbaşı",
    price: "220 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Kebap",
    brand: 12,
  },
  {
    id: 75,
    name: "Pideli Köfte",
    price: "200 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Köfte",
    brand: 12,
  },
  {
    id: 76,
    name: "Urfa Usulü Ciğer",
    price: "110 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Kebap",
    brand: 12,
  },
  {
    id: 77,
    name: "Sebzeli Kıyma",
    price: "200 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Kebap",
    brand: 12,
  },
  {
    id: 78,
    name: "Tavuk Şiş",
    price: "180 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Kebap",
    brand: 12,
  },
  {
    id: 79,
    name: "Tavuk Kanat",
    price: "200 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Kebap",
    brand: 12,
  },
  {
    id: 80,
    name: "Köfte",
    price: "180 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Köfte",
    brand: 12,
  },
  {
    id: 81,
    name: "Çay",
    price: "10 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 82,
    name: "Çay - Fincan",
    price: "14 ₺",
    info: "",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 83,
    name: "Çay - Termos",
    price: "25 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 84,
    name: "Türk Kahvesi",
    price: "15 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 85,
    name: "Menengiç",
    price: "15 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 86,
    name: "Espresso",
    price: "30 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 87,
    name: "Cortado",
    price: "180 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 89,
    name: "Flat White",
    price: "35 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 90,
    name: "Latte",
    price: "40 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 91,
    name: "Cappuchino",
    price: "40 ₺",
    info: "Cappuchino",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 92,
    name: "Americano",
    price: "40 ₺",
    info: "Americano",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 93,
    name: "Mocha",
    price: "45 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Sicak",
    brand: 13,
  },
  {
    id: 94,
    name: "Su",
    price: "8 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 95,
    name: "Ayran",
    price: "15 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 96,
    name: "Kola",
    price: "45 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 97,
    name: "Soğuk Çay",
    price: "30 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 98,
    name: "Meyve Suyu",
    price: "30 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 99,
    name: "Gazoz",
    price: "30 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 100,
    name: "Soda",
    price: "15 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 101,
    name: "Berry Hibiscus",
    price: "45 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 102,
    name: "Cool Lime",
    price: "45 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 103,
    name: "Limonata",
    price: "45 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 104,
    name: "Passion",
    price: "45 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
  {
    id: 105,
    name: "Orman Meyvesi",
    price: "45 ₺",
    info: "Sebzeli",
    img: def,
    cat: "Soguk",
    brand: 13,
  },
];
export const allLabel = [
  { id: 0, name: "all", icon: "" },
  { id: 1, name: "Kahvaltı", icon: "" },
  { id: 2, name: "Soğuk Mezeler", icon: "fa-regular fa-snowflake" },
  { id: 3, name: "Sıcak Mezeler", icon: "fa-solid fa-fire" },
  { id: 5, name: "Pizza", icon: "fa-solid fa-pizza-slice" },
  { id: 6, name: "Lazanya", icon: "fa-solid fa-martini-glass-citrus" },
  { id: 7, name: "Çorba", icon: "fa-solid fa-martini-glass-citrus" },
  { id: 8, name: "Salata", icon: "fa-solid fa-martini-glass-citrus" },
  { id: 9, name: "lol" },
  { id: 10, name: "Köfte", icon: "fa-solid fa-martini-glass-citrus" },
  { id: 11, name: "Doner", icon: "fa-solid fa-hotdog" },
  { id: 12, name: "Burger", icon: "fa-solid fa-burger" },
  { id: 13, name: "Kizarmis", icon: "fa-solid fa-solar-panel" },
  { id: 14, name: "Makarna", icon: "fa-solid fa-solar-panel" },
  { id: 15, name: "Kebap", icon: "fa-solid fa-solar-panel" },
  { id: 16, name: "Sicak", icon: "fa-solid fa-solar-panel" },
  { id: 17, name: "Soguk", icon: "fa-solid fa-solar-panel" },
];
export const logos = [
  { id: 1, img: `${img}`, name: "leyla" },
  { id: 2, img: `${img2}`, name: "mucmer" },
  { id: 3, img: `${img3}`, name: "solo" },
  { id: 4, img: `${img4}`, name: "SEFA" },
  { id: 5, img: `${img5}`, name: "Mufa" },
  { id: 6, img: `${img6}`, name: "Reyhan" },
  { id: 7, img: `${img7}`, name: "lobik" },
  { id: 8, img: `${img8}`, name: "1312 burgar" },
  { id: 9, img: `${img9}`, name: "fred moudy" },
  { id: 10, img: `${img10}`, name: "Pion" },
  { id: 11, img: `${img11}`, name: " ghuha" },
  { id: 12, img: `${img12}`, name: "hisaralti" },
  { id: 13, img: `${img13}`, name: "Soon" },
];
